import React from 'react';
import PropTypes from 'prop-types';
import Card from './Card';

const Cards = ({ baseUrl, references }) => {
  if (!references?.length) return null;
  return (
    <div className="grid grid-cols-4-lg">
      {references.map((item) => (
        <Card key={item.id} baseUrl={baseUrl} {...item?.attributes} />
      ))}
    </div>
  );
};

Cards.propTypes = {
  references: PropTypes.array,
  baseUrl: PropTypes.string,
};
export default Cards;
