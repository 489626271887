import React from 'react';
import PropTypes from 'prop-types';
import PageLayout from '../shared/PageLayout';
import Hero from '../page-components/homePage/Hero';
import Solutions from '../page-components/homePage/Solutions';
import History from '../page-components/homePage/History';
import References from '../page-components/homePage/References';
import Partners from '../page-components/homePage/Partners';
import Articles from '../page-components/homePage/Articles';
import CTASection from '../page-components/homePage/CTA';
import Service from '../page-components/homePage/Service';
import axios from '../api/axios';
import { graphql } from 'gatsby';
import ClientCarousel from '../page-components/homePage/ClientCarousel';

const IndexPage = ({ serverData }) => {
  const {
    heroReferences,
    references,
    clients,
    partners,
    allSolutions,
    solutions,
  } = serverData;
  return (
    <PageLayout solutions={allSolutions}>
      <Hero references={heroReferences} />
      <Solutions solutions={solutions} />
      <History />
      <References references={references} />
      <Partners list={partners} />
      <Articles />
      <ClientCarousel list={clients} />
      <Service />
      <CTASection />
    </PageLayout>
  );
};

IndexPage.propTypes = {
  serverData: PropTypes.object,
};

export default IndexPage;

export const Head = () => <title>Home Page</title>;

export async function getServerData() {
  try {
    const { data: AllSolutionData } = await axios.get(
      '/api/solution-level-ones',
      {
        params: {
          fields: ['name', 'slug'],
          populate: {
            solution_level_twos: {
              fields: ['name', 'slug'],
              populate: {
                solution_level_threes: {
                  fields: ['name', 'slug'],
                  populate: {
                    product: {
                      fields: ['name', 'slug'],
                    },
                  },
                },
              },
            },
          },
        },
      },
    );
    const heroParams = {
      populate: {
        references: {
          fields: ['name', 'slug', 'description'],
          populate: {
            cover: {
              fields: ['url'],
            },
            icon: {
              fields: ['url'],
            },
          },
        },
      },
    };

    const clientParams = {
      populate: {
        list: {
          fields: ['name'],
          populate: {
            logo: {
              fields: ['url'],
            },
          },
        },
      },
    };

    const partnerParams = {
      populate: {
        list: {
          fields: ['name'],
          populate: {
            logo: {
              fields: ['url'],
            },
          },
        },
      },
    };

    const solutionParams = {
      populate: {
        list: {
          fields: ['name', 'slug'],
          populate: {
            icon: {
              fields: ['url'],
            },
          },
        },
      },
    };

    const referenceParams = {
      populate: {
        list: {
          fields: ['name', 'slug', 'description'],
          populate: {
            cover: {
              fields: ['url'],
            },
            icon: {
              fields: ['url'],
            },
          },
        },
      },
    };

    let homeData = {};
    try {
      const { data } = await axios.get('/api/home', {
        params: {
          populate: {
            hero: heroParams,
            partner: partnerParams,
            client: clientParams,
            solution: solutionParams,
            reference: referenceParams,
          },
        },
      });
      homeData = data?.data?.attributes;
    } catch {
      console.log('Failed to fetch home data');
    }

    const heroReferenceData = homeData?.hero?.references?.data;
    const solutionData = homeData?.solution?.list?.data;
    const clientData = homeData?.client?.list?.data;
    const partnerData = homeData?.partner?.list?.data;
    const referenceData = homeData?.reference?.list?.data;

    return {
      props: {
        heroReferences: heroReferenceData,
        solutions: solutionData,
        clients: clientData,
        partners: partnerData,
        references: referenceData,
        allSolutions: AllSolutionData?.data?.map((item) => ({
          title: item?.attributes?.name,
          url: item?.attributes?.slug,
          iconUrl: item?.attributes?.icon?.data?.attributes?.url,
          subMenu: item?.attributes['solution_level_twos']?.data?.map(
            (subItem) => ({
              title: subItem?.attributes?.name,
              url: subItem?.attributes?.slug,
              subMenu: subItem?.attributes['solution_level_threes']?.data?.map(
                (subSubItem) => ({
                  title: subSubItem?.attributes?.name,
                  url: subSubItem?.attributes?.slug,
                }),
              ),
            }),
          ),
        })),
      },
    };
  } catch (error) {
    console.log(error);
    return {
      status: 500,
      headers: {},
      props: {},
    };
  }
}
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["HomePage", "Layout"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
