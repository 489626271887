import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { RightArrown } from '../../../shared/Icons/RightArrow';
import { API_URL } from '../../../api/axios';

const LinkCard = ({ attributes, baseUrl = '' }) => {
  const iconUrl = attributes?.icon?.data?.attributes?.url;
  const title = attributes?.name;
  const url = attributes?.slug;
  return (
    <div className="flex flex-col justify-between items-start-lg items-center py-10-xxl py-8 px-9-xxl px-6 shadow-gray-100 relative">
      {iconUrl && (
        <img
          src={`${API_URL}${iconUrl}`}
          height={70}
          className="w-auto max-h-70-px object-contain"
          alt={title}
        />
      )}
      {title && (
        <div className="mt-6-xxl mt-5">
          <Link
            to={baseUrl + url}
            className="mb-4 flex font-bold text-blue-900 text-xl-xxl text-base full-w-link"
          >
            {title}
          </Link>
        </div>
      )}
      <RightArrown fill="#2489CD" />
    </div>
  );
};

LinkCard.propTypes = {
  baseUrl: PropTypes.string,
  attributes: PropTypes.object,
};

export default LinkCard;
