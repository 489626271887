import React from 'react';
import PropTypes from 'prop-types';
import LinkCard from './LinkCard';
import Article from './Article';
import { useTranslation } from 'react-i18next';

const Solutions = ({ solutions }) => {
  const { t } = useTranslation();
  const { title, description } = t('solutions', {
    returnObjects: true,
  });
  return (
    <div className="py-20-xxl py-12 container px-6 mx-auto w-90-lg w-full">
      <h2 className="text-blue-900 font-bold text-5xl-xxl text-4xl text-center mb-5">
        {title}
      </h2>
      <p className="text-gray-400 text-base-xxl text-sm font-light text-center mb-6">
        {description}
      </p>
      <div className="grid grid-cols-5-lg gap-9-xxl gap-5 mb-24-xxl mb-16">
        {solutions?.map((item) => (
          <LinkCard key={item.id} {...item} baseUrl="/solutions/" />
        ))}
      </div>
      <Article />
    </div>
  );
};

Solutions.propTypes = {
  solutions: PropTypes.array,
};

export default Solutions;
