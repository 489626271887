import PropTypes from 'prop-types';
import React from 'react';
import { API_URL } from '../../../api/axios';

const Projects = ({ list }) => {
  if (!list?.length) return null;
  return (
    <div className="grid grid-cols-3-lg grid-cols-1 gap-8-xxl gap-6 w-90 h-400-px-lg mx-auto">
      {list?.map((item) => {
        const cover = item?.attributes?.cover?.data;
        const name = item?.attributes?.name;
        return (
          <div
            key={item.id}
            className="flex items-end justify-center p-12 relative h-full h-300-px"
          >
            {cover && (
              <img
                src={`${API_URL}${cover.attributes?.url}`}
                className="absolute top-0 left-0 h-full w-full max-w-full object-cover"
                height={cover.attributes?.height}
                width={cover.attributes?.height}
                alt={cover}
              />
            )}
            <div className="absolute top-0 left-0 h-full w-full max-w-full object-cover bg-blue-900-50" />
            {name && (
              <span className="font-bold text-lg-xxl text-base text-white text-center z-10 relative">
                {item?.attributes?.name}
              </span>
            )}
          </div>
        );
      })}
    </div>
  );
};

Projects.propTypes = {
  list: PropTypes.array,
};

export default Projects;
